import React from 'react';
import PropTypes from 'prop-types';

import './Faq.css';

function Faq(props) {
  const [showAnswer, setShowAnswer] = React.useState(false);

  return (
    <div className="faq">
      <div className="num">{ props.number }.</div>
      <div
        className="q"
        onClick={ () => setShowAnswer(!showAnswer) }
      >{ props.question }</div>
      { showAnswer &&
        <div className="a">{ props.answer }</div>
      }
    </div>
  );
}

Faq.propTypes = {
  number: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
}

export default Faq;
