import React from 'react';
import './Page.css';

function Page(props) {
  return (
    <div className="page">
      <div className="content">
        { props.children }
      </div>
    </div>
  );
}

export default Page;
