import './Accuracy.css';
import Page from '../Page/';
import chromo from './images/chromo.png';

function Accuracy() {
  return (
    <Page>
      <div className="alignc"><div className="title">Accuracy</div></div>
      <div className="accuracy-wrapper">
        <img src={ chromo } alt="Chromosome" id="chromosome" />
        <div>
          <h3>Results Guaranteed <i className="fa fa-check"></i></h3>
          Our DNA test is the most accurate DNA test available. The results will prove whether the tested man is the father or not.
          If he is NOT the father, the test results will be able to exclude him and will show a probability of 0%. If he IS the father,
          the test results will NOT be able to exclude him and will show a probability of at least 99.99%. Our accuracy is significantly
          higher than most other laboratories and more than 1 million times higher than government standards.
          <p>
            Tests are all run twice, following the most strict guidelines, which guarantees conclusive, accurate results. Results will
            be available within 3 business days from date of collection for our standard DNA test. Our chain of custody for our legal
            test provides results that can be used in any private or legal situation. Results can be used for changing names on Birth
            Certificates, obtaining life or health insurance for a child, child support, custody or wills. Test results can be used in
            any U.S. Court of law.
          </p>
          <div className="hint">
            <i className="fa fa-asterisk"></i> In rare cases genetic mutations can be identified. Our lab can not guarantee results &gt;99.99%
            probability when a genetic mutation occurs. In cases when these rare genetic mutations are identified additional extended
            testing may be needed to reach the 99.99% probability.
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Accuracy;
