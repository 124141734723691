import './Faqs.css';
import Page from '../Page/';
import Faq from './Faq';

function Faqs() {
  return (
    <Page>
      <div className="alignc">
        <div className="title">Frequently Asked Questions</div>
      </div>

      <Faq
        number={ 1 }
        question="Can DNA testing be done without the mother's sample?"
        answer="Yes the testing can be completed without having mom's sample. However about one case out of every 200 will need additional testing or the mother's sample. Therefore it is always recommended to include the mother's samples if possible."
      />
      <Faq
        number={ 2 }
        question="How are typical samples collected?"
        answer="Most collections are performed with a sterile swab. The swabs are rubbed inside the cheek to collect epithelial cells from all tested individuals. The collection is painless. Samples are collected from one person at a time and sealed in front of the clients."
      />
      <Faq
        number={ 3 }
        question="Can blood be used for testing?"
        answer="If you do not wish to painlessly have your epithelial cheek cells collected, you may use blood from one or all individuals being tested. Results are guaranteed with either blood or the epithelial cheek cells."
      />
      <Faq
        number={ 4 }
        question="How old does the child have to be?"
        answer="Testing can be completed on newborns, any age child or adult. We can even do prenatal testing. With prenatal testing there is a increased cost and the mothers Obstetric doctor will have to perform collection of the samples."
      />
      <Faq
        number={ 5 }
        question="Can testing be completed if the alleged father is deceased?"
        answer="Yes there are several ways to do the testing. Samples can be collected at the time of autopsy like cheek cells, blood or a tissue sample. Other options are if they had recent surgery or blood work at a hospital the next of kin can sign a release of the samples. Or if the grandparents are available, we can do Grandparentage collections."
      />
      <Faq
        number={ 6 }
        question="What if the mother and child and the alleged father live in different cities or states?"
        answer="Testing can be performed anywhere in the U.S. as well as in different countries. We have collection sites nationwide. Our client will be responsible for one shipping fee and we will cover the other shipping fee if it is in the U.S. As soon as both sets of samples get to our lab testing begins and results are available for release in 2 business days."
      />
      <Faq
        number={ 7 }
        question="How long does a standard paternity test take?"
        answer="Our tests are available for the alleged father and child or the alleged father, child and mother in 3 business days from the day of collection. Other testing may take additional testing time. Please talk to your case manager with any questions."
      />
      <Faq
        number={ 8 }
        question="Do I need a doctor or an attorney to get a legal DNA test?"
        answer="No, except if you live in the state of New York. Our lab requires a written prescription from a doctor in the state of New York or a court order. We can fax the prescription to your personal MD or MD of your request."
      />
      <Faq
        number={ 9 }
        question="Do any insurance companies cover the cost of a paternity test?"
        answer="No, DNA testing is not considered a medical necessity. There is no insurance company that will pay for DNA testing."
      />
      <Faq
        number={ 10 }
        question="Can payments be made on my bill?"
        answer="Payments can be made but we cannot release the results until the final payment is submitted."
      />

      <div className="youtube-wrap">
        <iframe width="853" height="480" src="https://www.youtube.com/embed/fJsNso9eRpo" frameBorder="0" title="youtube video" allowFullScreen></iframe>
      </div>
    </Page>
  );
}

export default Faqs;
