import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classes from 'classnames';

import './Service.css';
import popup from '../../../redux/popup';


function Service(props) {
  const serviceClasses = classes('service', { clickable: props.info });

  return (
    <div className={ serviceClasses } onClick={ () => props.setMessage(props.info) }>
      { props.new && <div className="new">NEW</div> }
      <div className="test">{ props.name }</div>
      { (props.composite)
        ? <div className="composite-icon">
          { props.icons }
        </div>
        : props.icons
      }

      <div className="price">${ props.price }</div>
      { props.info &&
        <div className="info">INFO</div>
      }
    </div>
  );
}

Service.propTypes = {
  name: PropTypes.string,
  info: PropTypes.string,
  price: PropTypes.number,
  icons: PropTypes.any,
  new: PropTypes.bool
}

const mapDispatchToProps = {
  setMessage: popup.actions.setMessage
};

export default connect(null, mapDispatchToProps)(Service);
