import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
  name: 'popup',
  initialState: '',
  reducers: {
    setMessage: (state, action) => action.payload
  }
});

export default popupSlice;
