export const scrollable = function(){
  const prev = document.documentElement.scrollTop || document.body.scrollTop;
  document.body.scrollTop = 1;

  let ele;
  if (document.body.scrollTop !== 0) {
    ele = document.body;
  }
  else {
    ele = document.documentElement;
  }

  ele.scrollTop = prev;
  return ele;
}();

const Pager = {
  to: 0,
  prev: null,
  pages: ['about', 'accuracy', 'services', 'faq', 'contact'],
  scroll_interval: null,

  scroll:function(pagenum){
    let total = document.querySelector('.home').clientHeight;
    const pages = document.getElementsByClassName('page');
    for(var i=0, y=pagenum;i<y;i++)
      total += pages[i].clientHeight;

    this.to = total;

    // for spam clicking
    clearInterval(this.scroll_interval);

    // to check if user changed scroll position since last interval
    var last_y = scrollable.scrollTop;

    if(scrollable.scrollTop < total){ // scroll down
      this.scroll_interval = setInterval(function(){
        if(scrollable.scrollTop < last_y || scrollable.scrollTop >= Pager.to || (scrollable.scrollHeight - scrollable.scrollTop) === scrollable.clientHeight)
          clearInterval(Pager.scroll_interval);
        else
          last_y = scrollable.scrollTop += Math.ceil((Pager.to - scrollable.scrollTop) * 0.2);
      }, 30);
    }
    else if(scrollable.scrollTop > total){ // scroll up
      this.scroll_interval = setInterval(function(){
        if(scrollable.scrollTop > last_y || scrollable.scrollTop <= Pager.to)
          clearInterval(Pager.scroll_interval);
        else
          last_y = scrollable.scrollTop -= Math.ceil((scrollable.scrollTop - Pager.to) * 0.2);
      }, 30);
    }
    else // equal. finished.
      return;
  },
};

export default Pager;
