import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Popup.css';
import popup from '../../redux/popup';

function Popup(props) {
  if (!props.popupMessage) {
    return null;
  }

  return (
    <div id="service-info" className="popup">
      <div className="modal">
        <div className="contain">
          <div id="service-name" className="pull-left"></div>
        </div>
        { props.popupMessage }
        <div className="alignr" style={ { marginTop:'10px' } }>
          <span className="close-popup" onClick={ () => props.setMessage(null)}>CLOSE</span>
        </div>
      </div>
    </div>
  );
}

Popup.propTypes = {
  popupMessage: PropTypes.string
};

const mapStateToProps = (state) => ({
  popupMessage: state.popup
});

const mapDispatchToProps = {
  setMessage: popup.actions.setMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
