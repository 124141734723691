import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBaby, faMars, faVenus, faUser, faMale, faFemale, faHome, faUsers } from '@fortawesome/free-solid-svg-icons';

import './Services.css';
import Page from '../Page/';
import Service from './Service';

function Services() {
  return (
    <Page>
      <div className="alignc">
        <div className="title">Services</div>
      </div>

      <div id="all-services">
        <Service
          name="Prenatal Paternity"
          info="Testing includes the mother, unborn child, and alleged father."
          price={ 1500 }
          icons={
            <FontAwesomeIcon icon={ faBaby } className="icon" />
          }
          new
        />

        <Service
          name="Gender Reveal"
          info="This test will determine whether your unborn child is male or female. There is an additional $30 blood collection fee. No other specimen collection fee will apply."
          price={ 155 }
          icons={
            <React.Fragment>
              <FontAwesomeIcon icon={ faMars } style={ { left:'36px' } } size="5x" />
              <FontAwesomeIcon icon={ faVenus } style={ { left:'114px' } } size="5x" />
            </React.Fragment>
          }
          composite
          new
        />

        <Service
          name="Standard DNA Paternity"
          info="Testing includes the mother, child, and alleged father."
          price={ 325 }
          icons={
            <React.Fragment>
              <FontAwesomeIcon icon={ faUser } style={ { left:'60px', top:'10px' } } size="5x" />
              <FontAwesomeIcon icon={ faFemale } style={ { left:'130px' } } size="4x" />
              <FontAwesomeIcon icon={ faMale } style={ { left:'30px' } } size="4x" />
            </React.Fragment>
          }
          composite
        />

        <Service
          name="Single Parent Paternity"
          info="Testing includes the child and the alleged father."
          price={ 325 }
          icons={
            <React.Fragment>
              <i className="fa fa-user" style={ { fontSize:'6em', left:'60px',top:'10px',textShadow:'0px 0px 10px #fff' } }></i>
              <i className="fa fa-male" style={ { fontSize:'4em', left:'30px' } }></i>
            </React.Fragment>
          }
          composite
        />

        <Service
          name="Twin Zygosity"
          info="Determine whether two children that were born at the same time are identical twins (share DNA) or are fraternal twins."
          price={ 375 }
          icons={
            <React.Fragment>
              <i className="fa fa-exchange" style={ { fontSize:'3em', left:'73px',top:'20px',textShadow:'0px 0px 10px #fff' } }></i>
              <i className="fa fa-male" style={ { fontSize:'4em',left:'128px',top:'10px' } }></i>
              <i className="fa fa-male" style={ { fontSize:'4em',left:'30px',top:'10px' } }></i>
            </React.Fragment>
          }
          composite
        />

        <Service
          name="Siblingship"
          price={ 525 }
          icons={
            <React.Fragment>
              <i className="fa fa-male" style={ { fontSize:'4em',left:'118px',top:'10px' } }></i>
              <i className="fa fa-female" style={ { fontSize:'4em',left:'40px',top:'10px' } }></i>
            </React.Fragment>
          }
          composite
        />

        <Service
          name="Personal DNA Code"
          info="Commonly used for: Child Identity, Immigration or Corrections"
          price={ 110 }
          icons={
            <i className="icon fa fa-male" />
          }
        />

        <Service
          name="Additional Child"
          info="Tested at the same time as the first child. Price is added to other service."
          price={ 125 }
          icons={
            <i className="icon fa fa-user-plus" />
          }
        />

        <Service
          name="Self (Home) Collection"
          info="Self-collected DNA Testing is available at a lower cost if you prefer to do the collection in the privacy of your own home. The results of a self-collected DNA Test are guaranteed, however the results are not usable in any court of law or government agency. We will provide you with a Collection Kit that contains all the necessary materials for the collection process. A case manager will go through the details with any client who wishes to use this service."
          price={ 240 }
          icons={
            <FontAwesomeIcon icon={ faHome } className="icon" />
          }
        />

        <Service
          name="Grandparentage"
          price={ 450 }
          icons={
            <FontAwesomeIcon icon={ faUsers } className="icon" />
          }
        />
      </div>

      <div className="hint"><i className="fa fa-asterisk"></i> There is a specimen collection fee of $15.00 - $50.00 that applies to each person being tested.</div>
      <br />
      <p>
        <div className="alignc"><div className="subtitle">We Accept</div></div>
        <div id="payment-types">Checks, cash, money orders, debit cards, Visa, Master Card, and Discover Card.</div>
      </p>
    </Page>
  );
}

export default Services;
