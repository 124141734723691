import './About.css';
import Page from '../Page/';
import logo from './images/ddc-logo.png';
import bbb from './images/bbb.svg';

function About() {
  return (
    <Page>
      <div className="alignc"><div className="title">About</div></div>
      <h3 id="mission">Our mission is to guarantee our clients peace of mind at the most competitive price</h3>
      <br />
      <h3>AT DNA DISCOVERY</h3>
      <div>
        <div>
          <div className="level1">
            Partnered with <a href="http://www.dnacenter.com/" target="blank" rel="noopener noreferrer">DNA Diagnostics Center</a>, we are
            dedicated to offering you the best in products and services. We look forward to working with you. DNA Discovery provides
            family relationship testing to the general public, health care providers, adoption agencies, legal professionals and embassies.
            Our office headquarters is located in Central Illinois. We have collection sites across the globe and nation wide. Our guaranteed
            test results combined with our highly trained professional staff provide you with the most reliable test on the market at a
            price affordable on any budget.
          </div>
          <div id="partner-logo">
            <img src={ logo } alt="Partnered with DNA Diagnostics Center" />
          </div>
          <div className="level2">
            Every case is unique and will be handled with strict confidentiality. The results are released only to
            the parties consenting to the DNA testing. Legal packets can be requested and sent to an attorney working with your case
            for a small additional fee. In the majority of cases, the results are available in 3 business days for the standard
            paternity test. Special testing like Grand Parentage, Siblingship, Twin Zygosity, or samples from the embryo or deceased
            person may take additional testing time. Please talk to your case manager about these special tests.
          </div>
          <div id="bbb">
            <a href="https://www.bbb.org/us/il/champaign/profile/paternity-test/dna-discovery-0724-90002005" target="blank" rel="noopener noreferrer">
              <img src={ bbb } alt="Better Business Bureau A+" />
            </a>
          </div>
          <div className="level3">
            Samples are collected by professional collection sites nationwide. The collection is a simple swab inside the mouth
            cheek. We use sterile, painless, buccal swabs that collect epithelial cells from inside the participants cheeks. We
            can also do the testing with blood, by request. Both blood and buccal swab results are guaranteed. It is legally
            required that our collectors take a picture of all tested parties at the time of the collection. Clients may also
            bring in a recent photo of all parties being tested.
          </div>
        </div>
      </div>
    </Page>
  );
}

export default About;
