import './Home.css';

function Home() {
  return (
    <div className="home">
      <div id="motto">
        <div id="tagline">Answer Life&rsquo;s Questions</div>
        <div className="company notranslate">DNA Discovery</div>
      </div>
    </div>
  );
}

export default Home;
