import _ from 'lodash';
import '@fontsource/roboto';

import './App.css';
import Nav from '../Nav';
import Home from '../Home';
import About from '../About';
import Accuracy from '../Accuracy';
import Services from '../Services';
import Faqs from '../Faqs';
import Contact from '../Contact';
import Popup from '../Popup';
import Pager, { scrollable } from '../../utils/Pager';

const sharableUrl = _.debounce(function() {
  let total = document.querySelector('.home').clientHeight;
  const pages = document.getElementsByClassName('page');
  const header_height = document.getElementById('header').clientHeight;

  if(scrollable.scrollTop + header_height <= total){
    document.getElementById('current-page')?.removeAttribute('id');

    if(window.history.replaceState)
      window.history.replaceState('', document.title, window.location.pathname);
    else
      window.location.hash = '';

    return;
  }

  for(var i=0, y=pages.length;i<y;i++){
    total += pages[i].clientHeight;

    if(scrollable.scrollTop + header_height <= total){
      document.getElementById('current-page')?.removeAttribute('id');
      window.location.hash = Pager.pages[i];
      document.getElementById('menu-list').children[i].id = 'current-page';
      break;
    }
  }

}, 400);

window.addEventListener('scroll', sharableUrl, false);

function App() {
  return (
    <div className="App">
      <Nav />
      <div id="pages">
        <Home />
        <About />
        <Accuracy />
        <Services />
        <Faqs />
        <Contact />
      </div>
      <Popup />
    </div>
  );
}

export default App;
