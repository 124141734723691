import React from 'react';

import './Contact.css';
import Page from '../Page/';
import map from './images/map.png';

function Contact() {
  const [isOpen, setIsOpen] = React.useState(false);

  function checkIsOpen() {
    var datetime = new Date();
    var day_of_week = datetime.getDay();
    let nowOpen = false;

    if (day_of_week !== 0) {
      var hour = datetime.getHours();
      nowOpen = hour > 7 && hour < 18;
    }

    if (isOpen !== nowOpen) {
      setIsOpen(nowOpen);
    }
  }

  React.useEffect(() => {
    checkIsOpen();
    const oneMinute = 60000;
    const id = setInterval(checkIsOpen, oneMinute);

    return () => {
      clearInterval(id);
    }
  }, []);

  return (
    <Page>
      <div className="alignc"><div className="title">Contact Us</div></div>
      <div style={ { overflow: 'auto', margin: '0 auto' } }>
        <div id="contain-contact">
          <h4>Info</h4>
          <table id="contact-info">
            <tr>
              <td><i className="fa fa-fw fa-envelope"></i> Email</td>
              <td><a href="mailto:questions@dnadiscovery.net">questions@dnadiscovery.net</a></td>
            </tr>
            <tr>
              <td><i className="fa fa-fw fa-phone"></i> Phone</td>
              <td><a href="tel:+12173980453">217-398-0453</a></td>
            </tr>
            <tr>
              <td><i className="fa fa-fw fa-fax"></i> Fax</td>
              <td>217-398-2904</td>
            </tr>
          </table>
          <h4>Hours</h4>
          <div id="hours-info" className="alignc">
            Monday to Saturday from 8am to 6pm.
            <p>
              We are currently { (isOpen)
                ? <span className="open">open</span>
                : <span className="closed">closed</span>
              }
            </p>
          </div>
        </div>
        <div id="map">
          <a href="https://www.google.com/maps?ll=40.099011,-88.309181&z=16&t=m&hl=en-US&gl=US&mapclient=embed&q=1518+Bridge+Point+Ln+Champaign,+IL+61822" target="_blank" rel="noopener noreferrer">
            <img src={ map } id="map" alt="Map" />
          </a>
        </div>
      </div>
    </Page>
  );
}

export default Contact;
