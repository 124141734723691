import React from 'react';

import './Nav.css';
import Pager from '../../utils/Pager';

function checkUrl(){
  const hash = (window.location.hash[0] === '#')
    ? window.location.hash.slice(1)
    : window.location.hash;
  const pagenum = Pager.pages.indexOf(hash);
  if(pagenum !== -1) {
    Pager.scroll(pagenum);
  }
}

function googleTranslateElementInit() {
  new window.google.translate.TranslateElement({pageLanguage: 'en', includedLanguages: 'ar,en,es,hi,it,pt,zh-CN', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE, autoDisplay: false}, 'google-translate-ele');
}

window.addEventListener('DOMContentLoaded', checkUrl, false);
window.addEventListener('load', googleTranslateElementInit, false);

function Nav() {
  const [showList, setShowList] = React.useState(false);

  const mappedMenu = Pager.pages.map((item, i) => (
    <span
      key={ i }
      onClick={ (e) => {
        Pager.scroll(i);
        setShowList(false);
      } }
    >
      { item }
    </span>
  ));

  return (
    <div id="header" className="no-select">
      <span className="mobile">
        <i
          id="menu"
          className="fa fa-bars"
          onClick={ () => setShowList(!showList) }
        />
      </span>
      <div id="google-translate-ele" />
      <span className="mobile">
        <div style={ { clear: 'both' } } />
      </span>

      <span id="menu-list" className="items desktop">
      { mappedMenu }
      </span>
      { showList &&
        <span id="menu-list" className="items mobile">
          { mappedMenu }
        </span>
      }
    </div>
  );
}

export default Nav;
